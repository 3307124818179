<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.szhqiot.top/hqsite/assets/product/chren.png" style="width: 100%; height: 100%" />
    </div>
    <div class="centertop">
      <label v-for="(item, index) in dataList" :key="index" :class="selectedIndex == index ? 'active' : 'boxsty'"
        @click.stop="selectBox(item, index)">
        {{ item.value }}
      </label>
    </div>
    <div class="videoions">
      <div class="title">{{ flagone[selectedIndex].title }}</div>
      <div>{{ flagone[selectedIndex].txt }}</div>
    </div>

    <div class="backimgs">
      <div class="videoions" style="padding:30px">
        <div class="message">
          <label class="borders"></label>
          行业现状
          <label class="borders"></label>
        </div>
        <div class="videoall">
          <div v-for='(item, index)  in flagone[selectedIndex].img' :key='index' class="imglist"
            :style="selectedIndex == 0 ? 'width:388px;height: 520px;' : 'width:388px;height: 420px;'">
            <img :src="require('../../assets/product/' + item.url)" style='width:100%;height: 225px;' />
            <div style="color:#291C37;font-size: 20px;font-weight: 500;padding:0 20px;">{{ item.tit }}</div>
            <div style="padding:20px;text-align:left">{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="videoions">
      <div class="message">
        <label class="borders"></label>
        一站式解决方案
        <label class="borders"></label>
      </div>
      <div class="bordertxt" v-if="selectedIndex == 0">
        综合考虑儿童、家长、学校多方面需求，华强技术首次提出“无屏幕”护眼概念，采用“极简”理念，打造了更适合孩子的蘑菇小兵防近视儿童定位电话。并通过蘑菇小兵进行数据采集，将孩子信息实时
        上传至家长管理端与定位管理系统，让家长精准掌握孩子动态，更好地守护孩子成长。</div>
      <div class="product" style="margin-top:50px;" v-if="selectedIndex == 0">
        <div style="width: 761px; height: 528px;background: #fcfcfc">
          <img style="width:100%;height:100%;"
            src="https://advertisement.szhqiot.top/hqsite/assets/product/rightimg.png" alt="">
        </div>
        <div class="righttxt">
          <div style="text-align:center;margin:30px 0;font-weight: bold;">学校定位管理平台介绍</div>
          <div class="borderline"></div>
          <div class="txts">
            从家长与学校需求出发，运用物联网、大数据、云计算等先进技术，基于儿童位置信息构建定位管理系统，实现儿童定位管理、轨迹查询、电子围栏及运动数据记录等多项功能。当孩子遇到紧急情况时，管理人员能够通过系统及时知晓，及时处理，避免意外发生，为儿童成长保驾护航。
          </div>
        </div>
      </div>
      <div v-else>
        <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/product/allimg.png"
          alt="">
      </div>
    </div>
    <div style="position: relative;padding-bottom:80px" v-if="selectedIndex == 0">
      <div class="videoions">
        <div class="message">
          <label class="borders"></label>
          智能设备概览
          <label class="borders"></label>
        </div>
        <div class="videoall" style="margin-left:10px">
          <div v-for='(item, index) in imgs' :key='index' class="imglist"
            style="padding:20px 0;width:346px;  background-color: #F6F6F6;border-radius: 20px;">
            <img :src="require('../../assets/product/' + item.url)" style='width:120px;height: 126px;' />
            <div style="font-size: 24px;font-weight: 400;color: #291C37;margin:50px 0 20px 0;">{{ item.txt }}</div>
            <div v-for="(items, indexs) in item.list" :key='indexs'>{{ items }}</div>
          </div>
        </div>
      </div>
      <div class="backimgover">
        <img src="https://advertisement.szhqiot.top/hqsite/assets/product/back.png" style="width: 100%; height: 100%" />
      </div>
    </div>
    <div class="videoions" v-if="selectedIndex == 1">
      <div class="message">
        <label class="borders"></label>
        方案价值
        <label class="borders"></label>
      </div>
      <div class="product" style="margin-top:50px;">
        <div style="width:50%; height: 250px;background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/product/1p.png"
            alt="">
        </div>
        <div class="righttit">
          <div class="title">平台功能更完善</div>
          <div class="txts">
            综合运用物联网、云计算、大数据、人工智能预测算法等先进技术，构建功能完善、系统稳定、安全可靠、集中统一的环境管控平台
          </div>
        </div>
      </div>
      <div class="product" style="margin-top:50px;">
        <div class="righttit">
          <div class="title">数据收集更完整</div>
          <div class="txts">
            整合多类型感知设备，覆盖环境温度、湿度、光照等多元化参数，收集到的环境数据更加准确和完整
          </div>
        </div>
        <div style="width:50%; height: 250px;background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/product/2p.png"
            alt="">
        </div>
      </div>
      <div class="product" style="margin-top:50px;">
        <div style="width:50%; height: 250px;background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/product/3p.png"
            alt="">
        </div>
        <div class="righttit">
          <div class="title">应用系统更灵活</div>
          <div class="txts">
            以用户多场景应用为导向，深化场景应用，从而打造实用、高效、高度灵活的系统
          </div>
        </div>
      </div>
      <div class="product" style="margin-top:50px;">
        <div class="righttit">
          <div class="title">数据清晰，便于管理</div>
          <div class="txts">
            对在库数据及分析报表以图形方式展现，环境状态一目了然，可灵活定制、兼容其他平台数据，减少管理成本，消除数据壁垒
          </div>
        </div>
        <div style="width:50%; height: 250px;background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/product/4p.png"
            alt="">
        </div>
      </div>
      <div class="product" style="margin-top:50px;">
        <div style="width:50%; height: 250px;background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/product/5p.png"
            alt="">
        </div>
        <div class="righttit">
          <div class="title">产品质优价廉</div>
          <div class="txts">
            依托华强供应链，自主研发生产硬件终端，有效降低终端成本。同时质检环节严格，产品质量有保障，售后无忧
          </div>
        </div>
      </div>
    </div>
    <div class="videoions">
      <div class="message">
        <label class="borders"></label>
        {{ flagone[selectedIndex].titles }}
        <label class="borders"></label>
      </div>
      <div class="product" :style="selectedIndex == 1 ? ' margin-bottom:220px;' : ''">
        <div class="bottomimg" v-for='(item, index)  in flagone[selectedIndex].imgs' :key="index">
          <img style="width:100%;height:100%;" :src="require('../../assets/product/' + item.url)" alt="">
          <div class="txtbottom">{{ item.txt }}</div>
          <div class="txtom">{{ item.txtbot }}</div>
        </div>
      </div>
    </div>


    <div v-if="selectedIndex == 1">
      <div class="backimgs">
        <div class="videoions" style="padding:30px">
          <div class="message">
            <label class="borders"></label>
            平台介绍
            <label class="borders"></label>
            <div style="font-size:18px;margin-top:30px;font-weight: 400;">环境监测管理平台：设备状态监测、设备信息化管理、告警查看、大数据决策分析等</div>
          </div>
          <div class="videoall">
            <div style="width:50%;">
              <img style="width:500px;height:100%;"
                src="https://advertisement.szhqiot.top/hqsite/assets/paltform/one1.png" alt="">

            </div>
            <div style="width:50%;">
              <img style="width:290px;height:100%;"
                src="https://advertisement.szhqiot.top/hqsite/assets/paltform/two2.png" alt="">
            </div>

          </div>
        </div>
      </div>
      <div class="videoions">
        <div class="message">
          <label class="borders"></label>
          智能硬件
          <label class="borders"></label>
          <div style="font-size:18px;margin-top:30px;font-weight: 400;">环境监测管理平台：设备状态监测、设备信息化管理、告警查看、大数据决策分析等</div>
        </div>
        <div class="videoall">
          <div style="width:50%;">
            <img style="width:500px;height:100%;"
              src="https://advertisement.szhqiot.top/hqsite/assets/paltform/thr3.png" alt="">
          </div>
          <div style="width:50%;color: #151F2B;">
            <div>环境监测七彩卡：NB-IoT通信，覆盖范围广，传输稳定；卡片类型多样化、可检测多种环境数据；重量轻、体积小、安装方便、快速部署；低功耗、持久续航；</div>
            <div style="margin:30px 0;text-align:left">监控参数多样化：温度、湿度、震动、光线强弱、倾斜角、PH值、化学物质、甲醛、PM2.5等均可监控。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="lastpart">
      <div class="lastxt">{{ flagone[selectedIndex].txts }}</div>
      <div style="font-size: 24px;">{{ flagone[selectedIndex].text }}</div>
      <button @click="goclick(selectedIndex)">{{ flagone[selectedIndex].btn }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {
      selectedIndex: 0,
      dataList: [
        { key: '1', value: "儿童关爱" },
        { key: '2', value: "城市/居家环境管理" }
      ],
      flagone: [
        {
          titles: '应用人群',
          title: '蘑菇小兵儿童智慧守护解决方案', txt: '基于物联网技术实现儿童安全管理与通讯需求，守护儿童健康成长！',
          img: [
            { url: "one.png", tit: '为保护青少年视力学校不允许携带有屏幕电子设备上学', txt: '教育部、国家卫生健康委员会等8部门联合印发《综合防控儿童青少年近视实施方案》，明确禁止中小学生携带智能手机和平板电脑进校园，部分城市学校甚至连带屏幕的儿童手表都不允许携带，放学后家长和孩子联系不便。' },
            { url: "two.png", tit: '教育信息化2.0时代多地市积极探索智慧校园模式', txt: '教育领域正在推进智慧教育、智慧校园建设等一系列教育信息化2.0行动。多地市已积极探索智慧校园模式，通过逐步解决校园教学的全向交互、校园环境的全面感知、校园管理的高效协同等问题，打造教育信息化、教学数字化，高效稳定、安全可靠的家校智慧化校园。' },
            { url: "thr.png", tit: '儿童走失原因多警方建议携带GPS防走失仪器', txt: '根据公安部发布的失踪儿童信息分析，很多失踪儿童案件其实都是“虚惊一场”，超过一半的失踪原因是孩子离家出走。基于统计，孩子走失后的24小时，是展开寻找、救援的“黄金时间”。警方建议让孩子随身携带智能防走失设备' }],
          imgs: [
            { url: "icon1.png", txtbot: '儿童防走失' },
            { url: "icon2.png", txtbot: '特殊人群防走失' },
            { url: "icon3.png", txtbot: '老人防走失' }],
          txts: '蘑菇小兵，不伤眼睛的定位电话', text: '只为守护孩子健康成长', btn: '了解更多产品信息 >>'
        },
        {
          titles: '应用场景',
          title: '居家环境监测管理方案', txt: '通过各类终端设备实时监测室内环境，时刻掌握周围环境情况，确保营造更健康的生活环境',
          img: [
            { url: "ones.png", tit: '环境污染严重伤害人体健康', txt: '随着经济发展，环境污染越来越严重，甲醛等有害气体对人体健康产生危害，极易引发各类严重疾病。' },
            { url: "twos.png", tit: '特殊货物运输过程中难以监管', txt: '教水果、海产品等生鲜货物运输过程中对于运输环境要求较高，需实时掌握周围环境状态防止货物损坏。' },
            { url: "thrs.png", tit: '农作物、养殖业需要适宜生长环境', txt: '农户在种植农作物以及养殖家畜家禽时需通过调整环境因素来保证其在适宜的环境下生长。' }],
          imgs: [
            { url: "icon4.png", txt: '在家庭、校园或办公室内根据需求部署温度、PM2.5、甲醛检测设备，实时监测室内环境情况' },
            { url: "icon5.png", txt: '实时监控货物运输过程中的状态（震动、倾斜角度等）及周围环境（光线、温度、湿度等）判断周围环境变化，从而帮助管理者实时了解货物状态是否异常' },
            { url: "icon6.png", txt: '根据采集的信息进行分析判断，及时推送环境异常信息，帮助用户准确地调节农作物、家禽、水产的生长环境，使其在适宜环境下生长以提高产量' }],
          txts: '华强技术：物联网整体解决方案提供商', text: '想获得专属解决方案？', btn: '去咨询 >>'
        }
      ],
      height: document.documentElement.clientWidth / 4.47,
      imgs: [
        { url: "litsone.png", txt: '定位功能', list: ['实时定位', '历史轨迹', '电子围栏'] },
        { url: "litstwo.png", txt: '通话功能', list: ['4G通话', '语音微聊', '家庭群聊'] },
        { url: "litsthr.png", txt: '生活服务', list: ['运动记录', '主题闹钟', '聆听录音'] }],
    };
  },
  methods: {
    selectBox(item, index) {
      this.selectedIndex = index;
    },
    goclick(index) {
      if (index == 1) {
        this.$router.push({ name: 'service' })
      } else {
        window.open('https://moguxiaobing.tmall.com/', '_blank');
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}

.backimgs {
  background-color: #CEE2F3;
  width: 100%;
  height: 750px;
}

.backimg {
  background-color: #CEE2F3;
  width: 100%;
  height: 650px;
}

.videoions {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 120px;
}

.lastpart {
  text-align: center;
  height: 280px;
  width: 100%;
  background-color: #E0F0FD;
  line-height: 30px;
}

.lastpart button {
  width: 313px;
  height: 56px;
  border-radius: 30px;
  background-color: #167BCE;
  color: #FFFFFF;
  border: none;
  font-size: 24px;
  margin: 50px 0;
}

.righttit {
  text-align: left;
  padding: 30px;
  box-sizing: border-box;
  width: 50%;
  color: #151F2B;
}

.righttit .txts {
  line-height: 36px;
  color: #151F2B;
  font-size: 17px;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}

.title {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 20px 0;
}




.borders {
  border-bottom: 1px solid #666666;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.videoall {
  width: 100%;
  display: flex;
  line-height: 30px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #747575;
}


.product {
  display: flex;
  width: 1200px;
  justify-content: space-between;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
}

.borderline {
  width: 75px;
  height: 1px;
  border-bottom: 1px solid #FFFFFF;
  margin-left: 36%;
  margin-bottom: 50px;
}

.txtbottom {
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #151F2B;
  line-height: 36px;
  margin: 30px;
}

.txtom {
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  position: relative;
  bottom: 70px;
}

.righttxt {
  text-align: left;
  padding: 30px 36px;
  box-sizing: border-box;
  width: 361px;
  height: 528px;
  color: #FFFFFF;
  background: #175085;
}

.lastxt {
  padding: 50px 0 30px 0;
  font-size: 34px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #121212;
  line-height: 12px;
}

.bordertxt {
  font-size: 18px;
  font-weight: 400;
  color: #151F2B;
  line-height: 36px;
  padding: 0 160px;
}

.rigbottom {

  width: 600px;
  height: 100%;
  text-align: left;
  padding: 0 60px;
  box-sizing: border-box;
}

.righttxt .txts {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px
}


.bottomimg {
  width: 388px;
  height: 496px;
}


.centertop {
  border-bottom: 1px solid #C9C9C9;
  width: 1200px;
  text-align: center;
  margin: 60px 0;
  margin-left: 18%;
  font-size: 24px;
}

.centertop label {
  width: 240px;
  padding: 20px 60px;
  display: inline-block;
}

.centertop .active {
  color: #2681D5;
  border-bottom: 2px solid #006FD4;
}

.centertop label:hover {
  color: #2681D5;
}

.imglist {
  z-index: 99;
  margin-right: 10px;
  background-color: #FFFFFF;
}

.backimgover {
  width: 100%;
  background: #fcfcfc;
  min-height: 256px;
  position: absolute;
  bottom: 80px;
  z-index: 1;
}
</style>