<template>
  <div>
    <div id='productDetailM' style="height: auto">
      <div class="breadcrubm">
        <div style="padding: 0 10px">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>蘑菇小兵</el-breadcrumb-item>
            <el-breadcrumb-item><a href="#">产品介绍</a></el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="product_top">
        <div style="width: 364px; height: 100%; background: #fcfcfc">
          <img style="width:100%;height:100%;" src="../../assets/img/蘑菇小兵364 283px.jpg" alt="">
        </div>
        <div style="flex: 1; height: 100%; padding: 0 33.5px;">
          <div class="product_top_right"> 
            <div style="font-size: 18px; font-weight: bold; margin-bottom: 10px">
              蘑菇小兵
            </div>
            <div style="font-size: 14px; display:flex;">
              <span style="font-weight:bold;color:#2B2B34;">产品用途：</span>
              <div style="width:500px;">
                没有屏幕的定位电话，高精度定位，孩子在哪里一目了然，支持北斗，基站、WIFI定位。
              </div>
            </div>
            <div style="font-size: 14px; ">
              <span style="font-weight:bold;color:#2B2B34;">产品颜色：</span>
              <span>
                <span style="margin-right:10px;">白色</span>
                <span style="margin-right:10px;">蓝色</span>
                <span style="margin-right:10px;">粉色</span>
                <span style="margin-right:10px;">绿色</span>
              </span>
            </div>
            <div style="font-size: 14px; display:flex;">
              <span style="font-weight:bold;color:#2B2B34;">购买渠道：</span>
              <div style="width:500px;position:relative;">
                <img class="link_img" src="../../assets/京东.png" alt="" style="top:-5px;">
                <img class="link_img" src="../../assets/天猫.png" alt="" style="top:-5px;left:50px;">
                <img class="link_img" src="../../assets/weixin.png" alt="" style="top:-5px;left:100px;">
                <img class="link_img" src="../../assets/dy.png" alt="" style="top:-5px;left:150px;">
              </div>
            </div>
            <div style="font-size: 14px;">
              <span style="font-weight:bold;color:#2B2B34;">咨询方式：</span>
              <span style="color:#0000FF">029-88665435</span>
            </div>
            <div style="width:122px;height:122px;position:absolute;top:81px;right:39px;">
              <img style="width:100%;height:100%;" src="../../assets/mg.png" alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="product_bottom_name">
          产品详情
      </div>
      <div class="product_bottom">
        <div style="width: 100%; height: auto;">
          <img style="width:100%;height:auto;" src="../../assets/img/1206px蘑菇小兵详情页.jpg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
  .breadcrubm{
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 15px;
  }
  .product_top{
    display: flex;
    height: 283px;
    width: 1200px;
    margin: auto;
    box-sizing: border-box;
    margin-top: 15px;
    margin-bottom: 46px;
    padding: 0 10px;
  }
  .product_top_right{
    width: 100%;
    height: 100%;
    position: relative;
    padding:30px 10px;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
  }
  .product_bottom{
    display: flex;
    height: auto;
    width: 1200px;
    margin: auto;
    box-sizing: border-box;
    margin-top: 15px;
    margin-bottom: 46px;
    padding: 0 10px;
  }
  .product_bottom_name{
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 15px;
    font-size: 22px;
    font-weight: bold;
    padding: 0 10px;
  }
  .link_img{
    position:absolute;
    cursor: pointer;
  }
</style>
