<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.szhqiot.top/hqsite/assets/product/oneback.png"
        style="width: 100%; height: 100%" />
    </div>
    <div class="centertop">
      <label v-for="(item, index) in dataList" :key="index" :class="selectedIndex == index ? 'active' : 'boxsty'"
        @click.stop="selectBox(item, index)">
        {{ item.value }}
      </label>
    </div>
    <div class="video">
      <div class="messageother">
        <label class="border"></label>
        {{ flagone[selectedIndex].title }}
        <label class="border"></label>
      </div>
      <div class="product_top" v-if="selectedIndex == 0">
        <div class="righttxt" style="width: 40%;margin-right:10px">
          <div class="txts">{{ flagone[selectedIndex].txt }}</div>
        </div>
        <div style="width: 60%; display:flex;justify-content: space-between;">
          <img :src="require('../../assets/product/' + flagone[selectedIndex].img)"
            style='width:348px;height: 180px;' />
          <img :src="require('../../assets/product/' + flagone[selectedIndex].imgs)"
            style='width:348px;height: 180px;' />
        </div>
      </div>
      <div class="product_top" v-else>
        <div class="righttxt" style=" padding: 30px 0;">
          <div class="txts">{{ flagone[selectedIndex].txt }}</div>
        </div>
        <div style="width: 50%; text-align:center; background: #fcfcfc">
          <img :src="require('../../assets/product/' + flagone[selectedIndex].img)"
            style='width:348px;height: 180px;' />
        </div>
      </div>
    </div>

    <div :class="flagone[selectedIndex].backimg">
      <div class="videoter">
        <div class="titother">
          功能特点 >>
        </div>
        <div class="videos" style="margin-top:30px">
          <div v-for='(item, index) in flagtwo[selectedIndex].imgone' :key='index'
            style="width:100%;height: 260px;text-align:center;">
            <img :src="require('../../assets/product/' + item.url)" style='width:110px;height: 110px;' />
            <div class="tip">{{ item.tip }}</div>
            <div class="txp">{{ item.txp }}</div>
          </div>
        </div>
        <div class="videos" style="margin-top:30px">
          <div v-for='(item, index) in flagtwo[selectedIndex].imgtwo' :key='index'
            style="width:100%;height: 260px; text-align:center">
            <img :src="require('../../assets/product/' + item.url)" style='width:110px;height: 110px;' />
            <div class="tip">{{ item.tip }}</div>
            <div class="txp">{{ item.txp }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <div style="color: #000529;font-size: 26px;text-align:left;padding:30px 0 60px 0;">
        应用场景 >>
      </div>
      <div class="videos">
        <div v-for='(item, index) in flagthr[selectedIndex].imgslist' :key='index' style="width:33%;">
          <img :src="require('../../assets/img/' + item.url)"
            :style="selectedIndex == 0 ? 'width:280px;height: 163px;' : 'width:380px;height: 210px;'" />
          <div style="font-size: 18px;margin:20px 0;font-weight: 500;color: #747575;">{{ item.txt }}</div>
          <div style="font-size: 16px;margin:20px 0;font-weight: 500;color: #747575;text-align:left;padding:0 20px">{{
              item.text
          }}</div>
        </div>
      </div>
      <div class="videos">
        <div v-for='(item, index) in flagthr[selectedIndex].imgslists' :key='index' style="width:33%;">
          <img :src="require('../../assets/img/' + item.url)"
            :style="selectedIndex == 0 ? 'width:280px;height: 210px;' : 'width:380px;height: 210px;'" />
          <div style="font-size: 18px;margin:20px 0;font-weight: 500;color: #747575;">{{ item.txt }}</div>
        </div>
      </div>
    </div>
    <div class="video">
      <div style="color: #000529;font-size: 26px;text-align:left;margin-bottom:60px">
        基础参数 >>
      </div>
      <div class="videos" v-for='(item, index) in flagfour[selectedIndex].textlist' :key='index'>
        <span class="left">{{ item.left }}</span>
        <span class="right">{{ item.txt }}</span>
      </div>
    </div>
    <!-- 当index首页 -->
    <div v-if="selectedIndex == 0" style="margin-top:30px;">
      <div class="video">
        <div class="messageother">
          <label class="border"></label>
          便携定位终端
          <label class="border"></label>
        </div>
        <div class="product_top">
          <div class="righttxt" style=" padding: 30px 0;">
            <div class="txts">
              轻薄小巧，可以随身携带，也可以放置在车里、家里、贵重物品等任何需要定位监管的地方，是一款充电式便携防丢定位器。设备同步接受北斗与GPS信号，结合Wi-Fi定位、LBS定位，定位更快更精准。</div>
          </div>
          <div style="width: 50%; text-align:center; background: #fcfcfc">
            <img :src="require('../../assets/product/t.png')" style='width:348px;height: 180px;' />
          </div>
        </div>
      </div>
      <div class="backimg">
        <div class="videoter">
          <div class="titother">
            功能特点 >>
          </div>
          <div class="videos" style="margin-top:30px">
            <div v-for='(item, index) in imgone' :key='index' style="width:100%;height: 260px;text-align:center;">
              <img :src="require('../../assets/product/' + item.url)" style='width:110px;height: 110px;' />
              <div class="tip">{{ item.tip }}</div>
              <div class="txp">{{ item.txp }}</div>
            </div>
          </div>
          <div class="videos" style="margin-top:30px">
            <div v-for='(item, index) in imgtwo' :key='index' style="width:100%;height: 260px; text-align:center">
              <img :src="require('../../assets/product/' + item.url)" style='width:110px;height: 110px;' />
              <div class="tip">{{ item.tip }}</div>
              <div class="txp">{{ item.txp }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="video">
        <div style="color: #000529;font-size: 26px;text-align:left;padding:30px 0 60px 0;">
          应用场景 >>
        </div>
        <div class="videos">
          <div v-for='(item, index) in imgslist' :key='index' style="width:33%;">
            <img :src="require('../../assets/img/' + item.url)" style=" width:220px;height: 310px;" />
            <div style="font-size: 18px;margin:20px 0;font-weight: 500;color: #747575;">{{ item.txt }}</div>
            <div style="font-size: 16px;margin:20px 0;font-weight: 500;color: #747575;text-align:left;padding:0 20px">{{
                item.text
            }}</div>
          </div>
        </div>
      </div>
      <div class="video">
        <div style="color: #000529;font-size: 26px;text-align:left;margin-bottom:60px">
          基础参数 >>
        </div>
        <div class="videos" v-for='(item, index) in textlist' :key='index'>
          <span class="left">{{ item.left }}</span>
          <span class="right">{{ item.txt }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {
      selectedIndex: 0,
      dataList: [
        { key: '1', value: "定位终端" },
        { key: '2', value: "环境感知终端" },
        { key: '3', value: "无线感知终端" },
        { key: '4', value: "生理参数感知终端" },
        { key: '5', value: "安全信息感知终端" },
        { key: '6', value: "通话定位感知终端" },
      ],
      imgone: [
        { url: "1q.png", tip: '多重精准定位', txp: 'GPS、WIFI、基站等多种定位方式，室内外无死角全覆盖。' },
        { url: "2q.png", tip: 'SOS一键呼救', txp: '当在外遇到危险时，可通过 SOS一键报警，家人第一时间了解情况。' },
        { url: "3q.png", tip: '围栏布控', txp: '划定电子围栏安全区域，进出围栏都有提醒。' }],
      imgtwo: [
        { url: "4q.png", tip: '轨迹查看', txp: '随时查看动态轨迹，历史轨迹保留30天。' },
        { url: "5q.png", tip: '持久续航', txp: '采用高密度聚合物锂电池，安全环保，超长待机， 避免频繁充电，省电模式下待机长达60天。' }],
      textlist: [
        { left: "定位方式", txt: 'GPS、 WiFi 、基站' },
        { left: "射频频段", txt: 'Band5、 Band8' },
        { left: "尺寸", txt: '43.6mm*43.6mm*16mm' },
        { left: "重量", txt: '约50g' },
        { left: "续航能力", txt: '2天（追踪模式）100天（纯待机）' },
        { left: "使用环境温度", txt: '-30℃至70℃' },
        { left: "使用环境湿度", txt: '15%-95%相对湿度' },
        { left: "防水能力", txt: 'IP54' },],
      imgslist: [
        { url: "y1.png", txt: '老人安全守护' },
        { url: "y2.png", txt: '孩子防走丢' },
        { url: "y3.png", txt: '爱车放丢失' },
        { url: "y4.png", txt: '贵重物品放丢失' },
        { url: "y5.png", txt: '安防与绩效考核管理' }],
      height: document.documentElement.clientWidth / 4.47,
      imgs: [
        { url: "litsone.png" },
        { url: "litstwo.png" },
        { url: "litsthr.png" }],
      flagone: [
        { title: '电动车定位终端', backimg: 'backimg1', txt: '终端体积小，便于安装和隐藏，不限制车型，支持48V到90V超宽电压输入，能适应多种复杂环境。具备高温警报、震动报警、定位跟踪、低电告警、历史轨迹查询等功能，以安防警告、高温警报为核心，车主可以通过手机客户端随时随地对车辆进行监控，当车辆异常时发送告警信息及时通知用户，为车主打造全方位监管安防。', img: 'phone0.png', imgs: 'phones.png' },
        { title: '环境检测卡片', backimg: 'backimg2', txt: '智慧环境监测卡片，目前可满足对多种参数的监控：光线强弱、颜色、温度、湿度、震动；后续可加入对空气质量、特定化学物质等信息的采集，满足多场景应用需求。', img: 'phone1.png', },
        { title: '地磁车位感应器', backimg: 'backimg3', txt: '地磁车位感应器解决方案意在帮助路边停车管理人员远距离监视和控制停车场，力保城市停车有序性。解决方案帮助相关场所配建车泊位，用于为工作人员及外来活动的人员提供车位，进而按照自身的管理方式维护场所正常运作、停车秩序、费用收取，以实现市民停车难、企业管理难、政府疏导难的重要工作。', img: 'phone4.png' },
        { title: '温感定位耳标', backimg: 'backimg4', txt: '智能温感定位耳标，实时采集动物的位置、温度等生理信息数据，经由NB-IoT网络传输到畜牧综合管理平台或APP端，主要解决畜牧业放牧管理难、疾病发现难、活体贷款难的问题。', img: 'phone2.png', },
        { title: '烟雾报警器', backimg: 'backimg5', txt: 'NB-IoT烟雾报警器是国家力推的物理联网智能烟雾报警器，采用国家标准，获得国家消防认证。', img: 'phone3.png' },
        { title: '儿童定位电话', backimg: 'backimg6', txt: '新概念无屏护眼定位电话，本产品以“简约纯净”为理念，采用无屏幕设计，专注通话、定位等核心功能，长续航、低辐射，在使用中不影响视力，有效防止对电子设备的沉迷。', img: 'phone.png', },
      ],
      flagtwo: [
        {
          imgone: [
            { url: "01.png", tip: '电池管理', txp: '通过加装电源控制和自检模块，实现电量显示，智能充放电监测等功能，让充放电更加安全，有效延长电池使用寿命。' },
            { url: "02.png", tip: '实时定位', txp: '采用北斗+GPS+基站定位，自动切换，覆盖范围广，让爱车永远在线。' },
            { url: "03.png", tip: '异动警报', txp: '不间断的多重定位，姿态传感器每分钟收集数百次的车辆数据，对比数据变化，异动信息即时警报。' }],
          imgtwo: [
            { url: "04.png", tip: '电子围栏', txp: '设定指定区域后，车辆进入或者离开围栏是都会记录并且触发警报，告警信息自动发送到监控中心及车主手机。' },
            { url: "05.png", tip: '高温警报', txp: '设备可实时感知环境温度，温度达到阈值时提醒车主及时处理，对电动车着火起到预警功效。' },
            { url: "06.png", tip: '轨迹查询', txp: '车主可通过平台查询车辆的历史行驶轨迹，轨迹保留30天。' }],
        },
        {
          imgone: [
            { url: "11s.png", tip: '运行稳定', txp: 'NB-IoT通信，覆盖范围广，传输稳定。' },
            { url: "22s.png", tip: '涵盖全面', txp: '卡片类型多样化、可检测多种环境数据。' },
            { url: "33s.png", tip: '安装便利', txp: '重量轻、体积小、可快速部署。' }],
          imgtwo: [
            { url: "44.png", tip: '参数多样', txp: '温度/湿度/震动/光线强弱/倾斜角/PH值/化学物质/甲醛/PM2.5等均可监控。' },
            { url: "55.png", tip: '持久续航', txp: '电池容量大、低功耗、续航更持久。' }],
        },
        {
          imgone: [
            { url: "31t.png", tip: '检测精准', txp: '采用NB-IoT物联网的信息融合技术，感应灵敏度极高≥99.99%，能进行复杂停车环境的高精度检测。' },
            { url: "32t.png", tip: '品质保障', txp: '外壳采用工程塑钢PC材质，基于工程学及力学的外观设计，能承受极大的外力冲击。' },
            { url: "33t.png", tip: '管理者(云平台)', txp: '车辆流量分析分流引导服务停车收费统计违停逃费管理' }],
          imgtwo: [
            { url: "34t.png", tip: '运营者(手持终端)', txp: '实时停车地图车位资源提醒收费跑漏统计运营收益统计员工管理考核' },
            { url: "35t.png", tip: '车主(公众号/小程序)', txp: '实时空位查询停车分流引导线上支付费用补缴违停费用车牌绑定与寻车' }],
        },
        {
          imgone: [
            { url: "1w.png", tip: '温度监控', txp: '内置温度传感器，实时监控动物的健康状况，不再错过牛生病的救治的黄金时间，避免造成养殖户惨痛的损失。' },
            { url: "2w.png", tip: '精确定位', txp: '音质清晰，随时接听,多重定位功能，动物跑到了哪里，一看就知道，放牧、散养再也没有操不完的心。' },
            { url: "3w.png", tip: '电子围栏', txp: '当动物离开设置好的安全区域时，定位器自动发送报警信息，有效防止走失被盗现象的发生。' }],
          imgtwo: [
            { url: "4w.png", tip: '超长待机', txp: '大容量的聚合物锂电池，省去充电烦恼，正常模式待机可使用18个月。' },
            { url: "5w.png", tip: '防拆设计', txp: '耳钉式造型设计，搭配防盗扣牢牢固定，还有防拆警报陪伴养殖的整个生长周期。' },
            { url: "6w.png", tip: '外观轻巧', txp: '一个火柴盒的大小，比一颗鸡蛋的还轻的重量。' },
            { url: "7w.png", tip: '抗冻防寒', txp: '电池采用耐寒耐高低温材料，恶劣环境中也可以正常工作。' }],
        },
        {
          imgone: [
            { url: "1d.png", tip: '灵敏检测', txp: '全方位敏锐捕捉烟雾，烟雾达到报警值立即报警鸣叫及指示灯闪烁提醒。' },
            { url: "2d.png", tip: '远程防护', txp: '烟雾报警器通过与云平台连接，实现消防状态实时可视化管控，当设备监测到异常时，系统实时发出多重报警。' },
            { url: "3d.png", tip: '安装简易', txp: '免布线、免接电、免网关，采用双面胶、螺丝钉两种安装方式，安装更方便。' }],
          imgtwo: [
            { url: "4d.png", tip: '支持集中管理', txp: 'APP支持添加多个烟雾报警器，实现统一管理，报警信息可通过APP、电话、短信、微信公众号多重远程报警。' },
            { url: "5d.png", tip: '超低功耗', txp: '采用低功耗设计，正常使用可达3年，免去频繁更换电池的烦恼。当电压低或者出现故障时，APP自动发出提示。' }],
        },
        {
          imgone: [
            { url: "1.png", tip: '多重定位', txp: '支持GPS、GLONASS、北斗、基站、WiFi、AGPS、Galileo、加速度传感器八重高精度定位，定位范围更广更精准。' },
            { url: "2.png", tip: '双向通话', txp: '音质清晰，随时接听,不管孩子在图书馆还是公园，关爱永不失联' },
            { url: "3.png", tip: '紧急呼救', txp: '当孩子突发紧急情况，家长立刻知晓，避免意外发生。' }],
          imgtwo: [
            { url: "4.png", tip: '家庭群聊', txp: '温家庭微聊，轻松沟通亲子互动，支持多人对讲，让距离不再是问题。' },
            { url: "5.png", tip: '智能闹钟', txp: '自主设定闹钟，语音提醒孩子规律作息' },
            { url: "6.png", tip: '历史轨迹', txp: '180天云端存储记录，查询历史纪录' },
            { url: "7.png", tip: '勿扰模式', txp: '灵活设置上课禁用时段，不影响课堂秩序' }],
        }
      ],
      flagthr: [
        {
          imgslist: [
            { url: "t1.png", txt: '掌握家人骑行安全' },
            { url: "t2.png", txt: '电动车防盗' },
            { url: "t3.png", txt: '电动车高温预警' },
            { url: "t4.png", txt: '安全充放电' }],
        },
        {
          imgslist: [
            { url: "11.png", txt: '室内温湿度监控' },
            { url: "22.png", txt: '特殊品物流监控' },
            { url: "33.png", txt: '农作物生长环境监控' }],
          imgslists: [
            { url: "44.png", txt: '室内安防监控' },
            { url: "55.png", txt: 'AIOT-环境感知终端' },
            { url: "66.png", txt: '自然环境监控' }],
        },
        {
          imgslist: [
            { url: "1s.png", txt: '城市路边停车', text: '城市机动车保有量剧增，通过华强技术智慧停车解决方案 ，为车主提供方便的寻找车位、手机缴费等服务。减少城市拥堵，便利车主出行。' },
            { url: "2s.png", txt: '景区停车', text: '节假日旅游高峰期，景区停车数量剧增，通过华强技术智慧停车解决方案能有效监控车位使用情况，让车位利用率达到最高，提升运营效率。' },
            { url: "3s.png", txt: '社区停车', text: '小区私家车保有量剧增，通过华强技术智慧停车解决方案为车主提供方便的寻找车位服务，节约车主时间，让小区管理更和谐更智能。' }],
        },
        {
          imgslist: [
            { url: "1e.png", txt: '开放型牧场' },
            { url: "2e.png", txt: '圈养养殖场' },
            { url: "3e.png", txt: '散户养殖' }],
        },
        {
          imgslist: [
            { url: "1r.png", txt: '个人住宅安防' },
            { url: "2r.png", txt: '小区物业安防' },
            { url: "3r.png", txt: '城市安防' }],
        },
        {
          imgslist: [
            { url: "img1.png", txt: '带进学校 不影响学习' },
            { url: "img2.png", txt: '户外玩耍 随时随地互动' },
            { url: "img3.png", txt: '休息时 无干扰陪伴 ' }],
        },
      ],
      flagfour: [
        {
          textlist: [
            { left: "通讯标准", txt: 'NB-IOT' },
            { left: "异常报警", txt: '时间ms级' },
            { left: "工作温度", txt: '-20℃至85℃' },
            { left: "电源输入", txt: '常规48V，最大90V' },
            { left: "尺寸", txt: '32mm*44mm*16.5mm' }]
        },
        {
          textlist: [
            { left: "整机尺寸", txt: '85.5mm*53.9mm*4.5mm' },
            { left: "电压范围", txt: '3.5至4.2VDC' },
            { left: "正常工作电流", txt: '< 40mA@4V' },
            { left: "休眠工作电流", txt: '< 10uA@4V' },
            { left: "工作温度范围", txt: '-30至70℃' },
            { left: "内置电池容量", txt: '600mAh（聚合物锂电池）' },
            { left: "工作频段", txt: 'H-FDD Band1/2/3/4/5/8/12/13/14/17/18/19/20/26/27/28/66/70 任选其中一种频段' },
            { left: "通信标准", txt: 'NB-IoT' }]
        },
        {
          textlist: [
            { left: "产品尺寸", txt: '150mm*150mm' },
            { left: "产品重量", txt: 'IP65' },
            { left: "工作湿度", txt: '500g' },
            { left: "工作温度", txt: '≤95%非结露' },
            { left: "防护等级", txt: '40℃至85℃' },
            { left: "工作年限", txt: '≥5年' },
            { left: "安装方式", txt: '埋入式' },
            { left: "车辆检测率", txt: '99%' },
            { left: "检测灵敏度", txt: '可多级设置' },
            { left: "参数调整", txt: '检测阈值、心跳间隔' },
            { left: "校准功能", txt: '上电校准、命令校准' },
            { left: "状态监控", txt: '低电压报警、干扰报警' },
            { left: "远程升级", txt: '支持' },
            { left: "通信标准", txt: 'NB-loT' },
            { left: "工作频段", txt: '适用于电信、移动、联通网络' },
            { left: "发射功率", txt: '23dBm' }]
        },
        {
          textlist: [
            { left: "整机尺寸", txt: '52mm*45mm*32.6mm' },
            { left: "传感器", txt: '温度传感器' },
            { left: "通信制式", txt: 'NB-IoT' },
            { left: "天线形式", txt: '内置FPC NB/GPS FPC天线' },
            { left: "定位方式", txt: 'GPS+北斗双模' },
            { left: "供电方式", txt: '电池供电' },
            { left: "功耗", txt: '待机电流小于10UA' },
            { left: "工作温度范围", txt: '-30°C~+60°C' }]
        },
        {
          textlist: [
            { left: "工作原理", txt: '烟雾迷宫' },
            { left: "理论宽带", txt: '180kHz' },
            { left: "发射功率", txt: '23dBm±2dBm' },
            { left: "传输方式", txt: 'NB-IoT（Band5）' },
            { left: "工作环境", txt: '-20℃至60℃，10至90%无冷凝 相对湿度：≤95%（40℃±2℃无凝露)' },
            { left: "电源", txt: 'DC3V ' },
            { left: "外壳尺寸", txt: '106*56.5' },
            { left: "保护面积", txt: '参考 GB50116-2013《火灾自动报警系统设计规范》中的相关规定' },
            { left: "执行标准", txt: '参考 GB20517-2006《独立式感烟火灾探测报警器》中的相关规定' },
            { left: "安装方式/外壳", txt: '吸顶/PC+ABS' }]
        },
        {
          textlist: [
            { left: "SIM卡", txt: '支持可插拔卡槽与贴片sim卡兼容' },
            { left: "防尘防水", txt: 'IP65' },
            { left: "体积", txt: '67mm*42mm*19mm' },
            { left: "重量", txt: '50g' },
            { left: "电池容量", txt: '1000mAh' },
            { left: "使用时间", txt: '待机8天，工作模式3天，持续通话4小时' },
            { left: "通信方式", txt: '支持LTE/GSM（2G和4G）' },
            { left: "设备要求", txt: '安卓5.1以上，iOS11.0及以上版本手机' },
            { left: "工作温度", txt: '-20°C-70°C' },
            { left: "材质", txt: 'PC+ABS' }]
        }
      ]
    };
  },
  methods: {
    selectBox(item, index) {
      this.selectedIndex = index;
    },

  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}


.backimg {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/product/wback0.png');
}

.backimg1 {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/product/wback1.png');
}

.backimg2 {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/product/wback2.png');
}

.backimg3 {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/product/wback3.png');
}

.backimg4 {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/product/wback4.png');
}

.backimg5 {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/product/wback5.png');
}

.backimg6 {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/product/wback.png');
}

.backimg,
.backimg1,
.backimg2,
.backimg3,
.backimg4,
.backimg5,
.backimg6 {
  background-position: center center;
  background-size: 100% 100%;
  padding: 120px 0 50px 0;
}

.video {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.videoter {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  padding: 0 60px 30px 60px;
}

.titother {
  color: #FFFFFF;
  font-size: 26px;
  text-align: left;
  position: relative;
  bottom: 80px;
}

.lastpart {
  text-align: center;
  height: 280px;
  width: 100%;
  background-color: #E0F0FD;
  line-height: 30px;
  position: relative;
  top: 20px;
}

.lastpart button {
  width: 313px;
  height: 56px;
  border-radius: 30px;
  background-color: #167BCE;
  color: #FFFFFF;
  border: none;
  font-size: 24px;
  margin: 50px 0;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}

.title {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 20px 0;
}

.messageother {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2681D5;
  margin-bottom: 60px;
}

.messageother>.border {
  border-bottom: 2px solid #167BCE;
  width: 120px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.messages {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  margin: 60px 0;
}

.border {
  border-bottom: 1px solid #C9C9C9;
  width: 500px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.tip {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 46px;
}

.txp {
  padding: 0 30px;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}

.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 470px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.videos {
  width: 100%;
  display: flex;
}


.product_top {
  display: flex;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
}

.righttxt {
  width: 50%;
  height: 100%;
  text-align: left;
  box-sizing: border-box;
}

.rigbottom {

  width: 600px;
  height: 100%;
  text-align: left;
  padding: 0 60px;
  box-sizing: border-box;
}

.righttxt .txts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #747575;
  line-height: 30px
}


.bottomimg {
  width: 388px;
  height: 496px;
}


.centertop {
  text-align: center;
  margin: 60px 0;
  font-size: 24px;
}

.centertop label {
  width: 200px;
  height: 50px;
  line-height: 50px;
  margin: 0 1px;
  border-radius: 5px;
  background-color: #7799BB;
  display: inline-block;
  color: #FFFFFF;
}

.centertop .active {
  background-color: #0163B4;
}

.centertop label:hover {
  background-color: #0163B4;
}

.left {
  width: 30%;
  margin: 1px;
  height: 43px;
  font-size: 18px;
  background-color: #167BCE;
  text-align: left;
  padding-left: 120px;
  box-sizing: border-box;
  line-height: 44px;
  color: #FFFFFF;
}

.right {
  width: 69.5%;
  height: 43px;
  font-size: 18px;
  background-color: #EEEEEE;
  text-align: left;
  padding-left: 100px;
  box-sizing: border-box;
  line-height: 44px;
  color: #000000;
}
</style>