<template>
  <!-- 底部模块 -->
  <div class="backimgs">
    <div class="videoall">
      <div class="title" :style="flag == 2 || flag == 1 ? ' position: relative;top: 50px;' : ''">
        平台核心功能
      </div>
      <div class="videosducts" v-if="flag == 0">
        <div v-for='(item, index) in imgone' :key='index'>
          <img :src="require('../assets/indust/' + item.url)" style='width:109px;height: 89px;' />
          <div class="tip">{{ item.tip }}</div>
          <div class="txp">{{ item.txp }}</div>
        </div>

      </div>
      <div class="videosduct" v-if="flag == 3">
        <div v-for='(item, index) in imgtwo' :key='index' class="borderbox" style="width: 25%;">
          <img :src="require('../assets/indust/' + item.url)" style='width:85px;height: 80px;' />
          <div class="font">{{ item.tip }}</div>
          <div class="txp">{{ item.txp }}</div>
        </div>
      </div>
      <div class="videosducts" v-if="flag == 2">
        <div>
          <div v-for='(item, index) in imglist[1].list' :key='index'>
            <img :src="require('../assets/indust/' + item.url)" style='width:63px;height: 60px;' />
            <div class="font">{{ item.tit }}</div>
            <div class="fonts">{{ item.txt }}</div>
          </div>
        </div>
        <div class="boxder">
          <img :src="require('../assets/indust/' + imglist[1].url)" style='width:600px;height: 448px;' />
        </div>
        <div>
          <div v-for='(item, index) in imglist[1].lists' :key='index'>
            <img :src="require('../assets/indust/' + item.url)" style='width:63px;height: 60px;' />
            <div class="font">{{ item.tit }}</div>
            <div class="fonts">{{ item.txt }}</div>
          </div>
        </div>
      </div>
      <div class="videosducts" v-if="flag == 1">
        <div>
          <div v-for='(item, index) in imglist[0].list' :key='index'>
            <img :src="require('../assets/indust/' + item.url)" style='width:63px;height: 60px;' />
            <div class="font">{{ item.tit }}</div>
            <div class="fonts">{{ item.txt }}</div>
          </div>
        </div>
        <div class="boxder">
          <img :src="require('../assets/indust/' + imglist[0].url)" style='width:790px;height: 448px;' />
        </div>
        <div>
          <div v-for='(item, index) in imglist[0].lists' :key='index'>
            <img :src="require('../assets/indust/' + item.url)" style='width:63px;height: 60px;' />
            <div class="font">{{ item.tit }}</div>
            <div class="fonts">{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Lastpart",
  // 接受父组件的值
  props: {
    flag: Number,
    required: true
  },
  data() {
    return {
      imglist: [
        {
          url: 'back2.png',
          list: [
            { tit: '监控大屏显示', url: 'n1.png', txt: '实时违章信息滚动显示，实时显示违章图像信息' },
            { tit: '设置违章规则', url: 'n2.png', txt: '设置监控路段以及监控路口信息、超速围栏规则、逆行围栏规则等' },
            { tit: '违章信息管理', url: 'n3.png', txt: '实时违章数据显示、统计、审核、处理' }
          ],
          lists: [
            { tit: '防盗追踪管理', url: 'n4.png', txt: '高效轨迹分析算法，完成车主骑行行为识别，大数据分析管控，有效降低违章率' },
            { tit: '人车证信息管理', url: 'n5.png', txt: '车主信息、手机号码、牌照号码等基础信息统一采集、管理' },
            { tit: '违章分析', url: 'n6.png', txt: '高效轨迹分析算法，完成车主骑行行为识别，大数据分析管控，有效降低违章率' }
          ]

        },
        {
          url: 'back3.png',
          list: [
            { tit: '电子围栏管理', url: 'a1.png', txt: '在规划好的停车范围内，通过在系统划定高精度的电子围栏，车辆进出电子围栏系统自动识别' },
            { tit: '企业运营综合体系', url: 'a2.png', txt: '通过获取各家共享车企的车辆、平台等数据进行对比分析，设立综合评分体系对各共享车企/服务商进行考核评判，以分配车辆投放额度和区域运营权' },
            { tit: '共享车辆监管模块', url: 'a3.png', txt: '系统自动识别违规行为、生成违规信息、通知整改、企业信用评分、车辆分布、轨迹回放、投放配额与实际投放' }
          ],
          lists: [
            { tit: '违规整改模块', url: 'a4.png', txt: '生成违规信息，通知企业对违规行为整改。并对上报的整改结果审核。整改不达标不及时会被扣除企业信用评分' },
            { tit: '巡检管理', url: 'a5.png', txt: '监管系统的人工辅助手段。巡检人员通过APP精准定位疑似车辆，现场检查核实。并通过APP向系统提交违章凭证采集、管理' },
            { tit: '用户黑名单', url: 'a6.png', txt: '将用户纳入信用体系，根据用户用车记录违规行为。达到一定数量将用户记入黑名单，限制其用车资格' }
          ]

        }
      ],
      imgone: [
        { url: "m111.png", tip: '车辆高温告警', txp: '实时违章信息滚动显示，实时显示违章图像信息' },
        { url: "m22.png", tip: '入户充电告警', txp: '设置监控路段以及监控路口信息、超速围栏规则、逆行围栏规则等' },
        { url: "m33.png", tip: '违规充电告警', txp: '查询车辆位置信息、历史轨迹、盗抢车辆处理、警务信息分发、处理进度跟踪' }],
      imgtwo: [
        { url: "1.png", tip: '停车场及车位统计', txp: '以聚合的方式统计停车场的名称，总停车位数量和空闲停车位数量，并实时展示在管理平台' },
        { url: "2.png", tip: '停车监控', txp: '监控的数据包括车位编号、车牌号和停车时长' },
        { url: "3.png", tip: '人员管理', txp: '收费员新增和删减；数据管理：根据权限划分，不同角色获取不同权限的数据，支持对敏感数据的隐藏或者替换' },
        { url: "4.png", tip: '收费设置', txp: '管理员可根据不同地区的停车场设置不同的停车收费规则，同一停车场亦可设置多阶段的收费规则' },

        { url: "5.png", tip: '车位状态', txp: '实时可视化显示车位状态：红色代表车位已占用，蓝色代表车位空闲' },
        { url: "6.png", tip: '停车位管理', txp: '管理员可根据收费员管辖的区域将停车位分配给不同的收费员进行统一管理' },
        { url: "7.png", tip: '订单详细', txp: '包括停车订单创建车主、车牌号、车位编号、停车时长、停车金额、支付方式、账单明细以及订单交易状态等信息' },
        { url: "8.png", tip: '支付方式', txp: '系统支持微信、支付宝、POS机和扫码等多种方式支付停车费' },

        { url: "9.png", tip: '停车诱导', txp: '通过云计算、大数据等技术展示停车场的位置、车位使用情况等实时信息，帮助驾驶员高效找到停车位' },
        { url: "10.png", tip: '设备管理', txp: '管理员可对停车位和地磁感应器进行统一管理，包括车位编号、地磁编号、所属停车场、设备状态等' },
        { url: "11.png", tip: '账单管理', txp: '管理员可查看账单详细，对于未缴费或逃逸的车主，管理员通知其及时缴费，补交停车费方可再次停车' },
      ],

    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoall {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.title {
  text-align: center;
  margin: 30px 0;
  font-size: 36px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
}

.backimgs {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/indust/backall.png');
  background-position: center center;
  overflow: hidden;
  padding-top: 30px;
}

.font {
  font-size: 22px;
  font-weight: 500;
  color: #000529;
  margin: 10px 0;

}

.fonts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #636363;
  line-height: 25px;
  margin-bottom: 20px;
}

.videosduct {
  width: 100%;
  padding: 20px 0;
}

.videosducts {
  width: 100%;
  display: flex;
  padding: 100px 0;
}

.borderbox {
  float: left;
  height: 240px;
}



.txp {
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding: 0 20px;
  margin: 10px 0;
}

.boxder {
  position: relative;
  top: 90px;
  margin: 0 10px;
}
</style>