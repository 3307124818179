<template>
  <!-- 方案 -->
  <div v-if="flag == 2 || flag == 3">
    <div class="videoion">
      <div class="messages">
        <label class="borders"></label>
        方案价值
        <label class="borders"></label>
      </div>
      <div class="videoalls">
        <div v-for='(item, index) in flagone[flag].imglist' :key='index' style="width: 30%; height: 220px;">
          <img :src="require('../assets/indust/' + item.url)" style='width:127px;height:127px;' />
          <div class="tits" style="color:#121212">{{ item.tit }}</div>
          <div class="txt" style="color:#747575">{{ item.txt }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="backimg" v-else>
    <div class="videoion">
      <div class="messages" style="color: #FFFFFF;">
        <label class="borderm"></label>
        方案价值
        <label class="borderm"></label>
      </div>
      <div class="videoalls" v-if="flag == 0">
        <div v-for='(item, index) in tablist[dex].imglist' :key='index' style="width: 30%; height: 220px;">
          <img :src="require('../assets/indust/' + item.url)" style='width:127px;height:127px;' />
          <div class="tit">{{ item.tit }}{{dex}}mm</div>
          <div class="txt">{{ item.txt }}</div>
        </div>
      </div>
      <div class="videoalls" v-else>
        <div v-for='(item, index) in flagone[flag].imglist' :key='index' style="width: 30%; height: 220px;">
          <img :src="require('../assets/indust/' + item.url)" style='width:127px;height:127px;' />
          <div class="tit" :style="flag == 1 ? 'height:80px' : ''">{{ item.tit }}</div>
          <div class="txt">{{ item.txt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  // 接受父组件的值
  props: {
    flag: Number,
    dex: Number,
    required: true
  },
  data() {
    return {
      tablist: [
        {
          imglist: [//方案价值
            { url: "t1.png", tit: '降低管理复杂性', txt: '终端设备对车辆信息进行全天候抓取，上传至云平台后管理人员线上即可实时查看、追踪，实现车辆系统化、智能化管控。' },
            { url: "t2.png", tit: '架构成熟稳定性强', txt: '终端设备对车辆信息进行全天候抓取，上传至云平台后管理人员线上即可实时查看、追踪，实现车辆系统化、智能化管控。' },
            { url: "t3.png", tit: '大数据精准预测', txt: ' 智能化动态跟踪，多维度数据统计分析，精准锁定高危人群及违规行为，防患于未然。' },
            { url: "t4.png", tit: '降低管理成本', txt: '根据大数据统计结果及趋势分析，管理人员可立即制定对应管理措施并进行效果跟踪，时间短，效率高，节省人力.' },
          ]
        },
        {
          imglist: [//方案价值
            { url: "t31.png", tit: '解决城市停车资源供需', txt: '整合城市停车场信息，实时同步城市停车位热力图，深挖停车资源，提高车位利用率，达到区域停车资源供需平衡目的。' },
            { url: "t32.png", tit: '降低城市停车位管理成本', txt: '整合汇集各个相关子系统，实现城市停车位资源全覆盖，对城市各个停车场进行标准化管理，降低管理成本和系统运行成本。' },
            { url: "t37.png", tit: '提升城市停车位管理及服务水平', txt: ' 规范停车收费标准，通过终端采集数据做到车辆驶入、驶出均有据可依，通过梯次收费、强化分时、分区差别化等停车策略，满足车主短时停车需求。' },
            { url: "t38.png", tit: '缓解城市道路交通拥堵', txt: '规范路边停车，解决违停及道路拥堵等城市难题，通过系统对车辆进行分流引导，避免交通堵塞。.' },
          ]
        },
        {
          imglist: [//方案价值
            { url: "t31.png", tit: '加强公务车监管', txt: '通过安装终端设备，全面实现电子化和信息化管理，加强公务车监管力度。' },
            { url: "t32.png", tit: '控制运营成本', txt: '通过对公务车透明化管理，车辆行驶里程，油耗，行驶记录，维修档案等均可实现线上查看及管理，可避免公车私用、私油公报、燃油费、过路费虚报、高成本汽修汽配费用等问题。' },
            { url: "t33.png", tit: '提升调度效率', txt: ' 车队管理员直观快速地掌握公务车位置、出车情况、车况、车务调度、等信息，遇到突发事件可统一调度，极大地提高用车效率、车辆调度能力及服务水平。' },
          ]
        },
      ],
      flagone: [
        {
          imglist: [//方案价值
            { url: "t1.png", tit: '降低管理复杂性', txt: '终端设备对车辆信息进行全天候抓取，上传至云平台后管理人员线上即可实时查看、追踪，实现车辆系统化、智能化管控。' },
            { url: "t2.png", tit: '架构成熟稳定性强', txt: '终端设备对车辆信息进行全天候抓取，上传至云平台后管理人员线上即可实时查看、追踪，实现车辆系统化、智能化管控。' },
            { url: "t3.png", tit: '大数据精准预测', txt: ' 智能化动态跟踪，多维度数据统计分析，精准锁定高危人群及违规行为，防患于未然。' },
            { url: "t4.png", tit: '降低管理成本', txt: '根据大数据统计结果及趋势分析，管理人员可立即制定对应管理措施并进行效果跟踪，时间短，效率高，节省人力.' },
          ]
        },
        {
          imglist: [//方案价值
            { url: "t11.png", tit: '建立社区电动自行车管理系统信息“数据库”', txt: '集成社区电动自行车数字化管理平台，实现电动自行车问题处置快速响应机制，杜绝电动自行车带来的火灾事故等安全隐患，多方位协助打造平安社区。' },
            { url: "t12.png", tit: '提高物业管理效率带动社区经济发展', txt: '在智慧物业模式下，员工的动态和轨迹、社区效益等信息均可在平台查阅，这给物业管理者管理员工带来了极大便利；构建的线上平台为社区O2O服务提供了可能，有助区域内经济发展。' },
            { url: "t13.png", tit: '是打造智慧城市的基础', txt: ' 智慧社区是发展智慧城市的关键内容之一。社区是城市的细胞，因此是智慧城市的重要组成分，也是对智慧城市概念的继承、发展和实施。' },
          ],
        },
        {
          imglist: [//方案价值
            { url: "f1.png", tit: '传统消防转型为智慧消防', txt: '达到火灾防控“自动化”、救援指挥“智能化”、队伍管理“精准化”的目的。' },
            { url: "f2.png", tit: '消除消防安全隐患', txt: '可视化图表实时监测消防设备状态，及时消除消防安全隐患。' },
            { url: "f3.png", tit: '提升防火管理效能', txt: ' 由系统实现对日常工作的有效监管，减轻消防设备管理的压力，节约人力物力，提高消防管理效益。' },
          ]
        },
        {
          imglist: [//方案价值
            { url: "f11.png", tit: '物联网养牛，提升养殖效益', txt: '统一的身份识别标识，实现肉牛活体抵押贷款，帮助养殖户扩大养殖规模；实时监控牛只健康状况及养殖环境，有效降低牛只病死率；牧场数据实时监测及信息化管理，提升养殖专业化水平，降低养殖成本' },
            { url: "f12.png", tit: '低风险放贷，无后顾之忧', txt: '牛只统一信息化管理，便于确权；抵押资产引入第三方实施监管，不改变相关人员的贷后管理责任；为抵押物办理财产保险，金融机构为第一受益人，降低放贷风险' },
            { url: "f13.png", tit: '牛只信息透明化，监管方便', txt: ' 牛只体温异常提前得知，防患于未然，担保风险可控；牛只数字化，每头牛拥有自己的“身份证”，便于监管；贷款结清，牛耳标摘除，有力保障资产安全，实现对抵押物发的有效监管' }]
        },
        {
          imglist: [//方案价值
            { url: "t21.png", tit: '降低高价值资产丢失率', txt: '通过终端定位设备对高价值固定资产进行实时定位，防止丢失；丢失后通过定位终端设备上传数据进行追踪，提高找回率。' },
            { url: "t22.png", tit: '实现资产管理可视化', txt: '资产管理云平台建立线上智能化管理系统，规划资产管理流程标准模块：资产管理、盘点管理、报表管理、维保信息、通知管理等，使管理流程清晰化，提升管理效率。' },
            { url: "t23.png", tit: '提倡资产利用率', txt: ' 通过智能算法对平台内资产数据进行分析，实现统一调度管理，让资产调度高效化，有效提升资产利用率。' },
          ],
        },
      ],
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.videoion {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  height: 600px;
}

.messages {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  padding: 60px 0;
}

.borderm {
  border-bottom: 1px solid #FFFFFF;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.borders {
  border-bottom: 1px solid #666666;
  width: 200px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}







.videoalls {
  width: 100%;
  display: flex;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #747575;
}

.tits {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding: 10px 30px;
  line-height: 40px;
  color: #FFFFFF;
}

.tit {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding: 10px 30px;
  line-height: 40px;
  color: #FFFFFF;
}

.txt {
  font-size: 16px;
  padding: 0 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 36px;
  text-align: left;
}


.backimg {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/img/backp.png');
  background-position: center center;
  background-size: 100% 100%;
}
</style>