<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.szhqiot.top/hqsite/assets/news.png" style="width: 100%; height: 100%" />
    </div>
    <div style="height: auto">
      <div style="
          width: 1200px;
          margin: 0 auto;
          box-sizing: border-box;
          margin-top: 15px;
        ">
        <!-- <div style="font-size: 22px; font-weight: bold; padding: 0 10px">
          新闻列表
        </div> -->
        <div class="title">
          <label @click="gopage('companyRef')"> 走进我们</label> >
          <label @click="gopage('newsReport')"> 公司动态</label>
        </div>
      </div>
      <div v-for="(item, i) in report1" :key="i" class="report_list" @click='detail(item)'>
        <div style="width: 245px; height: 100%; background: #d3dce6;">
          <img :src="item.thumbnail" style="width: 100%; height: 100%" />
        </div>
        <div style="flex: 1; height: 100%; padding: 0 33.5px">
          <div style="width: 100%; height: 100%; position: relative">
            <div style="font-size: 18px; font-weight: bold; margin-bottom: 10px">
              {{ item.title }}
            </div>
            <div style="font-size: 14px; color: #7a7a83;">
              <p>{{ item.comment_text }}</p>
            </div>
            <div style="position: absolute; bottom: 10px; width: 100%">
              <span class="report_origin">来源：{{ item.origin }}</span>
              <span style="float: right; color: #7a7a83; font-size: 14px">{{ new Date(+new Date(new
                  Date(item.create_time).toJSON()) + 8 * 3600 * 1000).toISOString().replace(/T/g,
                    '').replace(/\.[\d]{3}Z/, '')
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="width:1200px;margin:auto;text-align:right;padding-right:60px;">
        <el-pagination background layout="prev, pager, next" :total=count page-size=5 @current-change="page">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {
      report: [
        {
          title: '神奇配件“智骑宝”，低成本实现智能电动车自由。',
          detail: '自上世纪80年代第一辆电动自行车诞生开始,我国的两轮电动车行业一直在不断发展,目前电动车更是成为大家日常出行最便捷的代步工具之一,小到农村百姓,大到城市工薪阶层,选择电动车代步的人群越来越多。据权威机构公布的数据显示,我国2020年两轮电动车销量达到4760万辆,并且已有专业机构预测,到2050年我国电动车保有量将达到4亿辆,这是一个十分惊人的数字。',
          date: '2021-08-03',
          origin: '西安网',
          image: '产品图.png'
        },
        {
          title: '新增上亿市场！“智骑宝”或将成为经销商新盈利增长点。',
          detail: '手机就能掌握车况,智能电动车备受青睐根据第一财经和天猫共同发布的消费报告,CBNDate消费大数据显示:随着物联网的发展,智能化电动车越来越受到大家的喜爱,渗透率逐年提升,增速超过电动车整体,智能化进程已普及到了各个子品类。在智能化浪潮渗透到各个行业之时,电动自行车还能“无动于衷”、“视而不见”吗?',
          date: '2021-07-28',
          origin: '中国融媒产业网',
          image: '产品图.png'
        },
        {
          title: '智骑宝：一款配件让电动车代理商快速盈利',
          detail: '最近几年，随着大家消费观念的转变，越来越多的人选择骑电动车上班，买电动车的人也越来越多。从表面看这种情况对电动车代理商来说是非常好的赚钱机会，但作为电动车代理商的我却感觉反倒比以前赚得更少了，相信不少同行和我有一样的体会：以前卖电动车，规则少，品牌少，竞争远远没有现在激烈，所以赚钱还是比较轻松的。现在虽然买电动车的人越来越多，但同样电动车的品牌也越来越多，再加上国家政策的影响，厂家压货严重，大家纷纷打起价格战，导致代理商的利润空间越来越小。',
          date: '2021-08-04',
          origin: '小华探车',
          image: '产品图.png'
        },
        {
          title: '神奇配件“智骑宝”，低成本实现智能电动车自由。',
          detail: '自上世纪80年代第一辆电动自行车诞生开始,我国的两轮电动车行业一直在不断发展,目前电动车更是成为大家日常出行最便捷的代步工具之一,小到农村百姓,大到城市工薪阶层,选择电动车代步的人群越来越多。据权威机构公布的数据显示,我国2020年两轮电动车销量达到4760万辆,并且已有专业机构预测,到2050年我国电动车保有量将达到4亿辆,这是一个十分惊人的数字。',
          date: '2021-07-28',
          origin: '菏泽日报',
          image: '产品图.png'
        }
      ],
      report1: [],
      count: 0,
      height: document.documentElement.clientWidth / 4.47
    };
  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    },
      // count
      this.params = {
        page: 0,
        size: 5
      },
      this.$api.eggDemo(this.params)
        .then(res => {
          this.report1 = res.data.data.array
          this.count = res.data.data.allcount
        }).catch(err => {
          console.log(err)
        })
  },
  methods: {
    detail(val) {
      console.log(val)
      localStorage.setItem('newsId', val.id)
      this.$router.push('/newsDetail')
    },
    gopage(tag) {
      this.$router.push({ name: tag })
    },
    page(e) {
      console.log(e)
      this.params = {
        page: e - 1,
        size: 5
      },
        this.$api.eggDemo(this.params)
          .then(res => {
            this.report1 = res.data.data.array
            console.log(this.report1)
          }).catch(err => {
            console.log(err)
          })
    }
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}

.report_list {
  display: flex;
  height: 184px;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
  cursor: pointer;
}

.report_origin {
  background: #e9e9e9;
  padding: 2px;
  font-size: 14px;
  color: #7a7a83;
}

.title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #121212;
  padding-left: 10px;
  margin: 10px 0 45px 0;
}

.title label:hover {
  color: #0163B4;
}
</style>
