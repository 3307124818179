<template>
  <div id="service">
    <div class="address_top" :style="{height:height+'px'}">
      <img src="../../assets/关于我们banner02.jpg" style="width: 100%; height: 100%" />
    </div>
    <div class="address_box">
      <div class="title">
        联系地址
      </div>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content bg-purple box_img">
            <img src="../../assets/深圳.jpg" alt="" style="width:100%;height:100%;">
          </div>
          <div class="box_b">公司总部</div>
          <div class="box_n">
            <p>电话：400-825-8822</p>
            <p>邮箱：hqtech.sales@szhq.com</p>
            <p>地址：广东省深圳市深南中路华强路口华强集团1号楼</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple box_img">
            <img src="../../assets/研发中心.jpg" alt="" style="width:100%;height:100%;">
          </div>
          <div class="box_b">西安营销中心</div>
          <div class="box_n">
            <p>电话：400-825-8822</p>
            <p>邮箱：hqtech.sales@szhq.com</p>
            <p>地址：陕西省西安市高新区新加坡腾飞科汇城16楼</p>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple box_img">
            <img src="../../assets/西安.jpg" alt="" style="width:100%;height:100%;">
          </div>
          <div class="box_b">产品研发中心</div>
          <div class="box_n">
            <p>电话：400-825-8822</p>
            <p>邮箱：hqtech.sales@szhq.com</p>
            <p>地址：陕西省西安市高新区新加坡腾飞科汇城16楼</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import map from  '../../components/map.js'

export default {
  data() {
    return {
      height:document.documentElement.clientWidth/4.47
    };
  },
  mounted(){
    window.onresize = ()=>{
      this.height = document.documentElement.clientWidth/4.47
    }
  },
  methods: {
    
  },
};
</script>

<style lang="scss">
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .address_top{
    width: 100%;
    min-height:269px;
    background: #fcfcfc;
    
  }
  .address_box{
    width: 1200px;
    padding: 10px;
    margin: auto;
    box-sizing: border-box;
  }
  .title{
    width: 100%;
    margin: auto;
    color: #2b2b34;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
  }
  p{
    margin:8px 0;
  }
  .box_img{
    margin-bottom:11px;
    width:389px;
    height:219px;
  }
  .box_b{
    text-align:center;
    font-weight:bold;
  }
  .box_n{
    width:389px;
    height:219px;
    font-size:14px;
  }
  .i1nfo{
    position: relative;
    top: 0;
    right: 0;
    min-width: 0;
  }
</style>

