<template>
  <div id="newsReport">
    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.szhqiot.top/hqsite/assets/commpany.png" style="width: 100%; height: 100%" />
    </div>
    <div class="video">
      <div class="message">
        <label class="border"></label>
        公司简介
        <label class="border"></label>
      </div>
      <div class="product_top">
        <div style="width: 582px; height: 264px; background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/com.png" alt="">
        </div>
        <div class="righttxt">
          <div class="txts" style="margin-bottom:20px">
            深圳华强技术有限公司是一家物联网整体解决方案方案提供商，公司成立于2010年，隶属于深圳华强集团，总部位于深圳市福田区，并在全国多地设有研发中心及解决方案服务中心。
          </div>
          <div class="txts">公司专注于智慧城市领等方面，均居同行业前列，拥有产品开发、设计、检测以及生产制造的整个产品生命周期整体方案解决能力。华强技术致力于为企业量身定制具有市场竞争力的物联网解决方案，为客户创造丰厚价值。
          </div>
        </div>
      </div>
    </div>


    <div class="video">
      <div class="message">
        <label class="border"></label>
        新闻中心
        <label class="border"></label>
      </div>
      <div class="listcenter">
        <div class="bordertxt">
          <div class="tittop">
            <img style="width: 15px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/aboutus/right.png" alt="">
            公司动态
            <img style="width: 15px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/aboutus/left.png" alt="">
          </div>
          <div class="product_tops" v-for='(item,index) in imglist' :key='index' @click='detail(item)'>
            <div style="width: 284px; height: 129px; background: #fcfcfc;position:relative;bottom:15px;">\
              <img style="width:100%;height:100%;" :src="item.thumbnail" alt="">
            </div>
            <div class="txtilt">
              <div class="timetil">{{ item.comment_text }}</div>
              <div class="time">{{ new Date(+new Date(new
                  Date(item.create_time).toJSON()) + 8 * 3600 * 1000).toISOString().replace(/T/g, '').replace(/\.[\d]{3}Z/, '')}}</div>
            </div>
          </div>
          <div style="text-align:left;margin:50px 20px;cursor:pointer;" @click='carouselClick(1)'>查看更多>></div>
        </div>
        <div class="bordertxt">
          <div class="tittop">
            <img style="width: 15px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/aboutus/right.png" alt="">
            行业动态
            <img style="width: 15px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/aboutus/left.png" alt="">
          </div>
          <div class="product_tops" v-for='(item,index) in imglists' :key='index' @click='detail(item)'>
            <div style="width: 284px; height: 129px; background: #fcfcfc">
              <img style="width:100%;height:100%;" :src="item.thumbnail" alt="">
            </div>
            <div class="txtilt">
              <div class="timetil">{{ item.comment_text }}</div>
              <div class="time">{{ new Date(+new Date(new
                  Date(item.create_time).toJSON()) + 8 * 3600 * 1000).toISOString().replace(/T/g, '').replace(/\.[\d]{3}Z/, '')}}</div>
            </div>
          </div>
          <div style="text-align:left;margin:50px 20px;cursor:pointer;" @click='carouselClick(2)'>查看更多>></div>
        </div>
      </div>
    </div>


    <div class="backimg">
      <div class="video" style="padding:30px">
        <div class="message" style="color: #FFFFFF;">
          <label class="borders"></label>
          荣誉资质
          <label class="borders"></label>
        </div>
        <div class="messages">
          <img style="width: 70px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/star.png" alt="">
          企业专利
          <img style="width: 70px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/stars.png" alt="">
        </div>
        <div class="videos">
          <div v-for='(item,index) in img' :key='index' style="width:25%; height: 110px;">
            <img :src="require('../../assets/aboutus/' + item.url)"
              style='width:100px;height:100%;margin-bottom:10px' />
            <div>{{ item.tit }}</div>
          </div>
        </div>
        <div class="messages">
          <img style="width: 70px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/star.png" alt="">
          集团荣誉
          <img style="width: 70px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/stars.png" alt="">
        </div>
        <div class="product_top">
          <img style="width: 580px; height: 100%;" src="https://advertisement.szhqiot.top/hqsite/assets/aboutus/bottom.png" alt="">
          <div class="rigbottom">
            <div class="txts" style="padding-bottom: 20px;">·
              华强集团位列2011年（第２5届）全国电子信息百强企业榜单，华强集团连续32年登榜华强集团荣获“广东省优秀企业”称号。
            </div>
            <div class="txts" style="padding-bottom: 20px;">·
              深圳华强集团有限公司的“华强”商标被国家工商行政管理总局商标局认定为中国驰名商标,华强集团荣获中国民营企业服务业100强。
            </div>
            <div class="txts" style="padding-bottom: 20px;">· 深圳华强集团有限公司入选“中国制造业企业500强。
            </div>
            <div class="txts" style="padding-bottom: 20px;">· 华强集团荣获2013年度深圳百强企业。
            </div>
            <div class="txts" style="padding-bottom: 20px;">· 华强集团入选2016年广东省企业500强、广东省民营企业100强。
            </div>
            <div class="txts" style="padding-bottom: 20px;">· 华强集团荣列2017中国电子信息行业创新能力50强企业。
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="video">
      <div class="message">
        <label class="border"></label>
        企业文化
        <label class="border"></label>
        <div style="font-size: 18px;font-weight: 300;margin-top:20px;">企业价值观：诚信 创新 和谐 共赢</div>
      </div>
      <div class="product_top">
        <div class="bottomimg" style="margin-right:35px;">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/photo.png" alt="">
        </div>
        <div class="bottomimg">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/photos.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {
      height: document.documentElement.clientWidth / 4.47,
      img: [
        { url: "icon1.png", tit: '发明专利' },
        { url: "icon2.png", tit: '实用新型专利' },
        { url: "icon3.png", tit: '外观设计专利' },
        { url: "icon4.png", tit: '计算机软件著作权' }],
      imglist: [],
      imglists: []
    };
  },
  methods: {
    carouselClick(val) {
      this.$router.push('/newsReport')
    },
    detail(val) {
      console.log(val)
      localStorage.setItem('newsId', val.id)
      this.$router.push('/newsDetail')
    },
  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
    this.params = {
      page: 0,
      size: 6
    },
      this.$api.eggDemo(this.params)
        .then(res => {
          this.imglist = res.data.data.array.slice(0, 3)
          this.imglists = res.data.data.array.slice(3, 6)
        }).catch(err => {
          console.log(err)
        })
  }
};
</script>
<style lang="scss" scoped>
p {
  text-indent: 2em
}

.backimg {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/img/backt.png');
  width: 100%;
  height: 1050px;
  background-position: center center;
}

.video {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 120px;
}


.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #121212;
  margin: 60px 0;
}

.messages {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  margin: 100px 0;
}

.border {
  border-bottom: 1px solid #C9C9C9;
  width: 320px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}


.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 320px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.listcenter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #FFFFFF;
}

.bordertxt {
  border: 1px solid #929292;
  width: 49%;
}

.product_top {
  display: flex;
  height: 283px;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
  color: #FFFFFF;
}

.product_tops {
  display: flex;
  margin: 20px;
}

.tittop {
  font-size: 24px;
  margin: 50px 0;
}

.txtilt {
  text-align: left;
}

.txtilt .time {
  margin: 20px;
}

.timetil {
  width: 300px;
  height: 90px;
  overflow: hidden;
  line-height: 30px;
  margin-left: 20px;
}

.righttxt {
  width: 595px;
  height: 100%;
  text-align: left;
  padding: 10px 60px 0 60px;
  box-sizing: border-box;
}

.rigbottom {

  width: 600px;
  height: 100%;
  text-align: left;
  padding: 0 60px;
  box-sizing: border-box;
}

.righttxt .txts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #747575;
  line-height: 30px
}


.bottomimg {
  width: 582px;
  height: 275px;

}
</style>