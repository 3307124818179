<template>
  <div>
    <div id='productDetail' style="height: auto">
      <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
        <img src="https://advertisement.szhqiot.top/hqsite/assets/news.png" style="width: 100%; height: 100%" />
      </div>
      <div class="breadcrubm">
        <div style="padding: 0 10px">
          <!-- <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/newsReport' }">新闻列表</el-breadcrumb-item>
            <el-breadcrumb-item><a href="#">详情</a></el-breadcrumb-item>
          </el-breadcrumb> -->
          <div class="title">
            <label @click="gopage('companyRef')"> 走进我们</label> >
            <label @click="gopage('newsReport')"> 公司动态</label> >
            <label @click="gopage('newsDetail')"> 详情</label>
          </div>
        </div>
      </div>

      <div class="product_bottom_name">
        {{ content.title }}
      </div>
      <div class="product_bottom" style="position:relative;font-size: 14px;">
        <div style="display:flex;width:300px;justify-content:space-around;position:absolute;right:0;">
          <div>来源:&nbsp;{{ content.origin }}</div>
          <div>时间:&nbsp;{{ new Date(+new Date(new Date(content.create_time).toJSON()) + 8 * 3600 *
              1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
          }}</div>
        </div>
      </div>
      <div class="product_bottom">
        <div style="width: 100%; height: auto;" v-html='content.gcontent'>
          <p>
            消火栓作为消防灭火的基础设施和基本保障，是减少火灾危害、保护市民财产安全的一道“防火墙”。然而传统消防栓分布广泛、存在管理难和维护不及时等问题，留下很大消防隐患，直接影响到紧急情况下消火栓的正常启用，贻误灭火救援时机。或是灭火救援时无水提供、危及城市公共安全等后果。
          </p>
          <div style="text-align:center;">
            <img src="https://advertisement.szhqiot.top/hqsite/assets/首页小图-烟感.jpg" alt="">
          </div>
          <p>
            消火栓作为消防灭火的基础设施和基本保障，是减少火灾危害、保护市民财产安全的一道“防火墙”。然而传统消防栓分布广泛、存在管理难和维护不及时等问题，留下很大消防隐患，直接影响到紧急情况下消火栓的正常启用，贻误灭火救援时机。或是灭火救援时无水提供、危及城市公共安全等后果。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product",
  components: {},
  data() {
    return {
      gcontent: '',
      title: '',
      origin: '',
      date: '',
      viewCount: '',
      content: {}
    };
  },
  methods: {
    gopage(tag) {
      this.$router.push({ name: tag })
    }
  },
  mounted() {
    this.newsId = localStorage.getItem('newsId')
    this.$api.newsDetail(this.newsId)
      .then(res => {
        console.log(res.data.data.array[0])
        //  this.gcontent = res.data.data.array[0].gcontent
        this.content = res.data.data.array[0]
      }).catch(err => {
        console.log(err)
      })
  }
};
</script>
<style lang="scss" scoped>
.breadcrubm {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 15px;
}

.product_bottom {
  display: flex;
  height: auto;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
}

.product_bottom_name {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 15px;
  font-size: 22px;
  font-weight: bold;
  padding: 0 10px;
  margin-top: 50px;
  ;
}

.link_img {
  position: absolute;
  cursor: pointer;
}

p {
  text-indent: 2em;
  font-size: 14px;
}

.title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #121212;
  padding-left: 10px;
}

.title label:hover {
  color: #0163B4;
}
</style>
