<template>
  <div id="service">
    <div style="width: 100%; background: #fcfcfc; min-height:269px;" :style="{ height: height + 'px' }">
      <img src="../../assets/banner2.jpg" style="width: 100%; height: 100%" />
    </div>
    <div style="width: 1200px; padding: 10px; margin: auto; box-sizing: border-box;">
      <div class="product_title">
        产品列表
      </div>
      <el-row :gutter="20">
        <el-col :span="6" v-for='item in img' :key='item'>
          <div @click='detail(item)' class="grid-content bg-purple"
            style="background:#fcfcfc;margin-bottom:11px;width:273px;height:364px;position:relative;">
            <img :src="require('../../assets/' + item.url)" alt="" style="height:100%;width:100%;">
            <div v-show='item.status' class="label">{{ item.name }}</div>
          </div>
        </el-col>
      </el-row>
      <!-- <div class="login">
        <div class="col" v-for='item in 6' :key='item' @click='detail(item)'>
            <div class="container">
                <div class="front" style="position:relative;">
                    <div class="inner" >
                        <img src="../../assets/蘑菇小兵.jpg" alt="" style="height:100%;width:100%;">
                        <div style="width:100%;font-size:18px;position:absolute;text-align:center;">智骑宝</div>
                    </div>
                </div>
                <div class="back">
                    <div class="inner">
                        <img src="../../assets/公众号.png" alt="" style="height:100%;width:100%;">
                    </div>
                </div>
            </div>
        </div>
      </div> -->
      <!-- <div class="login">
        <div class="col" v-for='item in img' :key='item' @click='detail(item)'>
            <div class="container">
                <div class="front" style="position:relative;">

                    <div class="inner" >
                        <img src="../../assets/蘑菇小兵.jpg" alt="" style="height:100%;width:100%;">
                        <div style="width:100%;font-size:18px;position:absolute;text-align:center;">智骑宝</div>
                    </div>

                    <img :src="require('../../assets/'+item.url)" alt="" style="height:100%;width:100%;">
                    <div style="width:100%;font-size:18px;position:absolute;text-align:center;bottom:5px;">智骑宝</div>
                </div>
                <div class="back">

                    <div class="inner">
                        <img src="../../assets/公众号.png" alt="" style="height:100%;width:100%;">
                    </div>

                    <img src="../../assets/公众号.png" alt="" style="height:100%;width:100%;">
                    <div style="width:100%;font-size:18px;position:absolute;text-align:center;bottom:5px;color:black">智骑宝</div>
                </div>
            </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import '../../style/style.css'
export default {
  data() {
    return {
      img: [
        { uid: 1, status: true, name: "智骑宝", url: "产品页小图替换1.jpg" },
        { uid: 2, status: true, name: "蘑菇小兵", url: "产品页小图替换2.jpg" },
        { uid: 3, status: true, name: "烟感", url: "产品页小图替换3.jpg" },
        { uid: 4, status: false, name: "敬请期待", url: "respect.png" }
      ],
      height: document.documentElement.clientWidth / 4.47
    };
  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
  },
  methods: {
    detail(val) {
      console.log(val)
      this.$router.push({ name: 'productDetail' })
    }
  },
};
</script>

<style lang="scss">
.product_title {
  width: 100%;
  margin: 16px;
  color: #2b2b34;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
  max-width: 80rem;
}

.login {
  width: 1200px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: left;
  ;
}

.login:after {
  content: "";
  flex: auto;
}

.cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.col {
  width: calc(25% - 2rem);
  margin: 1rem;
  cursor: pointer;
}

.container {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.front,
.back {
  background-size: cover;
  background-position: center;
  -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  min-height: 364px;
  height: auto;
  border-radius: 0px;
  color: #fff;
  font-size: 1.5rem;
}

.back {
  background: #cedce7;
  background: -webkit-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
  background: -o-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
}

.front {
  width: 273px;
  height: 364px;
}

.front:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: '';
  display: block;
  opacity: .6;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 0px;
}

.container:hover .front,
.container:hover .back {
  -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  position: absolute;
  left: 0;
  width: 100%;
  padding: 1rem;
  font-size: .7em;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
}

.container .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.container .front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.container:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.container:hover .front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.front .inner p {
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.front .inner p:after {
  content: '';
  width: 4rem;
  height: 2px;
  position: absolute;
  background: #C6D4DF;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -.75rem;
}

.front .inner span {
  color: rgba(255, 255, 255, 0.7);
  font-family: 'Montserrat';
  font-weight: 300;
}

.label {
  width: 100%;
  font-size: 18px;
  position: absolute;
  bottom: 10px;
  text-align: center;
  color: white;
}
</style>
