<template>
  <div class="homePage">
    <!-- 轮播 -->
    <div style="background:#ccc;width:100%;min-height:560px;" :style="{ height: height + 'px' }">
      <el-carousel class="carous" :autoplay='true' indicator-position="none">
        <el-carousel-item style="width: 100%;" v-for="(item,index) in carousel" :key="index">
          <img @click='carouselClick(item)' style="width: 100%; height: 100%" 
            :src="item.url" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 视频 -->
    <div class="video">
      <div class="title">我们的使命</div>
      <div class="message">
        <label class="border"></label>
        Our Mission
        <label class="border"></label>
      </div>
      <img src="https://advertisement.szhqiot.top/hqsite/assets/img/new.png" style='width:100%;height:100%;' />
      <!-- <div style="width: 370px; height: 209px; background: #fcfcfc">
        <video style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/video/product_intro.mp4" autoplay='true' loop  controls x5-video-player-type="h5-page"
        poster="">
            你的浏览器不支持video~  
        </video>
      </div>
      <div v-for='item in img' :key='item' style="width: 390px; height: 209px; background: #fcfcfc">
        <img :src="require('https://advertisement.szhqiot.top/hqsite/assets/img/'+item.url)" style='width:100%;height:100%;'/>
      </div> -->
    </div>
    <div class="backimg">
      <div class="video" style="padding:30px">
        <div class="title" style="color: #FFFFFF;">我们为合作伙伴带来什么</div>
        <div class="message" style="color: #FFFFFF;">
          <label class="borders"></label>
          What can we do for our partners
          <label class="borders"></label>
        </div>
        <div class="videos">
          <div v-for='(item, index) in img' :key='index' style="width: 30%; height: 220px;">
            <img :src="require('../../assets/img/' + item.url)" style='width:227px;height:100%;' />
            <div :class="index == 2 ? 'tits' : 'tit'">{{ item.tit }}</div>
            <div class="txt">{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <div class="title">我们的优势</div>
      <div class="message">
        <label class="border"></label>
        Our Advantages
        <label class="border"></label>
      </div>
      <div class="product_top">
        <div style="width: 595px; height: 100%; background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/one.png" alt="">
        </div>
        <div class="righttxt">
          <div class="titles">业务平台</div>
          <div class="txts">具备海量物联网硬件设备及用户接入能力，适配多场景物联网业务应用需求；基于模块化开发，运用自主开发的私有通信协议及保密认证，确保高效安全地实施物联网业务部署。确保高效安全地实施物联网业务部署。</div>
        </div>
      </div>
      <div class="product_top">
        <div class="righttxt">
          <div class="titles">研发人员</div>
          <div class="txts">超七成研发人员为硕士学历，同时拥有十年以上通信产品研发经验，团队具备完整的物联网解决方案开发能力。</div>
        </div>
        <div style="width: 595px; height: 100%; background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/tow.png" alt="">
        </div>
      </div>
      <div class="product_top">
        <div style="width: 595px; height: 100%; background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/thr.png" alt="">
        </div>
        <div class="righttxt">
          <div class="titles">供应链</div>
          <div class="txts">拥有国内最大的电子元器件分销平台，并与业内领先的核心供应商建立深度合作关系， VMI管理方面处于业界领先水平；
以客户为中心，采用由客户需求驱动的快速响应型管理模式，实现客户需求驱动生产制造。</div>
        </div>
      </div>
      <div class="product_top">
        <div class="righttxt">
          <div class="titles">生产制造</div>
          <div class="txts">生产中心拥有SMT自动高速贴片机、高精度检测设备及配套的测试和组装生产线，具有完善的电子产品制造管理、 品质控制能力和技术革新能力；能够在保质保量的情况下缩短项目周期，确保生产交期。</div>
        </div>
        <div style="width: 595px; height: 100%; background: #fcfcfc">
          <img style="width:100%;height:100%;" src="https://advertisement.szhqiot.top/hqsite/assets/img/last.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "homePage",
  components: {},
  data() {
    return {
      carousel: [
        { uid: 1, name: "bannernew.png", url: "https://advertisement.szhqiot.top/hqsite/assets/bannew.png" },
        { uid: 2, name: "backs.png", url: "https://advertisement.szhqiot.top/hqsite/assets/backs.png" }
      ],
      img: [
        { url: "foone.png", tit: '提供AIoT解决方案', txt: '我们拥有丰富的物联网、通信、互联网行业经验， 具备从硬件、软件、云平台等整套解决方案的规划能力。' },
        { url: "fotow.png", tit: '提供面向需求的一站式服务', txt: '从客户需求出发，将需求变成落地的产品方案，从开发、部署到运维提供一对一、精准化的“保姆式”服务。 具备从硬件、软件、云平台等整套解决方案的规划能力。' },
        { url: "fothr.png", tit: '输出核心能力：业务平台、 研发、供应链、生产制造', txt: '更有效率的全价值链协同管理，以成熟的技术能力，向客户提供AIoT软硬一体化解决方案，帮助客户及终端用户降本增效。 具备从硬件、软件、云平台等整套解决方案的规划能力。' },
      ],
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientWidth / 4.47
    };
  },
  mounted() {
    window.onresize = () => {
      this.width = document.documentElement.clientWidth,
        this.height = document.documentElement.clientWidth / 4.47
    }
  },
  methods: {
    carouselClick(val) {
      if (val.uid == 2) {
        // this.$router.push('/DetailZ')
      }
    }
  }
};
</script>
<style lang="scss" >
.carous {
  height: 100%;
  width: 100%;
  min-width: 1200px;
  background: #fcfcfc;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.video {
  width: 1200px;
  text-align: center;
   justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 120px;
}

.el-carousel__container {
  height: 100%;
  min-width: 1200px;
   justify-content: space-between;
  align-items: center;
}

.title {
  height: 50px;
  font-size: 38px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #121212;
  margin: 60px 0 10px 0;
}

.message {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #2681D5;
  margin-bottom: 60px;
}

.border {
  border-bottom: 1px solid #2681D5;
  width: 260px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.tit {
  margin-top: 40px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
}

.tits {
  margin-top: 5px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
}

.borders {
  border-bottom: 1px solid #FFFFFF;
  width: 160px;
  display: inline-block;
  position: relative;
  bottom: 8px;
  margin: 0 20px;
}

.videos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
  color: #FFFFFF;
}

.videos .txt {
  margin: 20px 0;
  padding-top: 20px;
  border-top: 1px solid #F8FAFB;
  text-align: left;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}

.backimg {
  background-image: url('https://advertisement.szhqiot.top/hqsite/assets/img/back.png');
  background-position: center center;
  width: 100%;
  height: 780px;
}

.product_top {
  display: flex;
  height: 283px;
  width: 1200px;
   justify-content: space-between;
  align-items: center;
  margin: auto;
  box-sizing: border-box;
  margin-top: 15px;
  margin-bottom: 46px;
  padding: 0 10px;
}

.righttxt {
  width: 595px;
  height: 100%;
  background: #fcfcfc;
  text-align: left;
  padding: 30px 60px;
  box-sizing: border-box;
}

.righttxt .titles {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #167BCE;
  margin-bottom: 30px;
  width: 98px;
  padding-bottom: 20px;
  border-bottom: 2px solid #167BCE;

}

.righttxt .txts {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #6B6B6B;
  line-height: 30px
}
</style>
